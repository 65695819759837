export default {
  token: null,
  userId: null,
  sessionGUID: null,
  sessionStamp: null,
  isCis: false,
  fees: false,
  geolocation: '',
  allowedgeo: false,
  allowedpv: true,
  exclusiveEnabled: true,
  landingEnabled: true,
  webOfficeMenu: false,
  powerline: false,
  isPremiumUser: false,
  countryList: []
}
