export default {
  getNotifications: (state) => {
    const returnData = state.notifications
    returnData.sort((a, b) => {
      return new Date(b.date) - new Date(a.date)
    })
    return returnData
  },
  getLastUpdate: (state) => {
    return state.lastupdate
  }
}
